$(document).ready(function () {
	var selectPickerOptions = {
		'container': 'body',
		'selectOnTab': true
	};
	$('.selectpicker').selectpicker(selectPickerOptions);



	var datePickerOptions = {
		'autoclose': true,
		'immediateUpdates': true,
		'language': 'ru',
		'orientation': 'bottom auto',
		'format': 'yyyy-mm-dd'
	};
	$('.input-group.date').datepicker(datePickerOptions);



	// Routes filter
	if ($('.filter').length > 0) {
		var filter = $('.filter');

		var dateHidden = filter.find('[type="hidden"][name="date"]');

		// Destroy datepicker
		var datepicker = filter.find('.input-group.date')
			.datepicker('destroy')
			.datepicker($.extend(datePickerOptions, {
				'format': 'd M', // uses modified short-months names in bootstrap-datepicker.ru.js
				'todayBtn': 'linked',
				'maxViewMode': 0,
				'startDate': new Date(),
				'endDate': new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
			}));


		datepicker.on('changeDate', function (e) {
			if (e.date) {
				var y = e.date.getFullYear();
				var m = e.date.getMonth() + 1;
				var d = e.date.getDate();

				var dateString = y + '-' + (m < 10 ? '0' + m : m) + '-' + (d < 10 ? '0' + d : d);

				dateHidden.val(dateString);
			}
		});

		// Set datepicker date if passed
		(function () {
			if (dateHidden.val()) {
				var timestamp = Date.parse(dateHidden.val());

				if (isNaN(timestamp) === false) {
					$('.input-group.date').datepicker('setDate', new Date(timestamp));
				}
			}
		})();



		// Bootstrap select
		var departure = $('[data-select="departure"]');
		var arrival = $('[data-select="arrival"]');

		var updateArrivalRoutes = function (e) {
			if (!departure.val()) {
				arrival.attr('disabled', 'disabled');
				arrival.selectpicker('refresh');
			} else {
				arrival.removeAttr('disabled');
			}

			if (departure.val() === arrival.val()) {
				arrival.selectpicker('val', '');
			}

			arrival.find('option').hide();
			arrival.find('[data-departure="' + departure.val() + '"]').show();

			arrival.selectpicker('refresh');
		}

		departure.on('changed.bs.select', updateArrivalRoutes);

		updateArrivalRoutes(null);
	}



	// Order 
	if ($('.order-form').length > 0) {
		var wrapper = $('.tickets-wrapper');
		var template = $('#tickets-template').html();
		var data = JSON.parse($('#tickets-data').text());
		var index = 0;
		var total = 0;



		var addTicket = function (model) {
			// Render template
			var ticket = $(template.replace(/{index}/ig, index));
			var dateHidden = ticket.find('[data-type="birthdate"]');

			ticket.find('.selectpicker').each(function (index, element) {
				// Get select data-type which reflect property name in model
				var type = $(element).data('type');

				// Get option with model's value
				var option = $(element).find('[value="' + model[type] + '"]');

				// Mark option as selected
				option.attr('selected', 'selected');

				// Initialize bootstrap-select
				$(element).selectpicker(selectPickerOptions);

				// Update price when select changed
				$(element).on('changed.bs.select', function () {
					updatePrice(ticket);
				});
			});

			// Set input values
			ticket.find('input[data-type]').each(function (index, element) {
				// Get input data-type which reflect property name in model
				var type = $(element).data('type');

				// Set input value
				$(element).val(model[type]);
			});

			// Initialize datepicker
			ticket.find('.input-group.date').datepicker({
				'autoclose': true,
				'format': 'dd.mm.yyyy',
				'language': 'ru',
				'orientation': 'bottom auto',
				'startView': 'decade',
				'startDate': '-100y',
				'endDate': '-6y',
			});

			// Set datepicker date if passed
			if (dateHidden.val()) {
				var timestamp = Date.parse(dateHidden.val());

				if (isNaN(timestamp) === false) {
					ticket.find('.input-group.date').datepicker('setDate', new Date(timestamp));
				}
			}

			// Update hidden birthdate input when date changed
			ticket.find('.input-group.date').on('changeDate', function (e) {
				if (e.date) {
					var y = e.date.getFullYear();
					var m = e.date.getMonth() + 1;
					var d = e.date.getDate();

					var dateString = y + '-' + (m < 10 ? '0' + m : m) + '-' + (d < 10 ? '0' + d : d);

					dateHidden.val(dateString);
				}
			});

			// Append to wrapper
			wrapper.append(ticket);

			// Update ticket price
			updatePrice(ticket);

			// Increment ticket index
			index++;
		}



		// Updates displayed ticket's price
		var updatePrice = function (ticket) {
			// Get price for selected ticket type
			var price = ticket
				.find('[data-type="type"]')
				.find('option:selected')
				.data('price');

			var template = ticket
				.find('.ticket-price')
				.data('template');

			// Update displayed price
			ticket
				.find('.ticket-price')
				.html(template.replace(/{price}/ig, price));

			// Update order total
			updateTotal();
		}



		// Updates displayed total price
		var updateTotal = function () {
			total = 0;

			wrapper.find('.order-ticket').each(function (index, element) {
				var ticket = $(element);

				// Get price for selected ticket type
				var price = ticket
					.find('[data-type="type"]')
					.find('option:selected')
					.data('price');

				total += Number(price);
			});

			// Update displayed price
			var totalBlock = $('.order-form')
				.find('[data-type="total-price"]');

			var template = totalBlock
				.data('template');

			totalBlock.html(template.replace(/{total}/ig, total));
		}



		// Adds new ticket to form
		$(document).on('click', '[data-type="add-ticket-button"]', function (e) {
			// Add ticket populated with default values
			addTicket({
				type: 1,
			});
		});



		// Removes ticket from form
		$(document).on('click', '[data-type="remove-ticket-button"]', function (e) {
			if (wrapper.find('.order-ticket').length > 1) {
				var ticket = $(e.target).parents('.order-ticket').first();

				ticket.remove();
			}
		});



		// Add tickets from JSON dump
		for (var index in data) {
			addTicket(data[index]);
		}
	}


	if ($('[data-fancybox="true"]').length > 0) {
		try {
			$('[data-fancybox="true"]').fancybox({
				padding: 0,
				margin: 0,
				loop: true,
				arrows: true,
				type: 'image',
				autoSize: false,
				autoResize: true,
				autoCenter: true,
				fitToView: true,
				aspectRatio: true,
				openSpeed: 0,
				closeSpeed: 0,
				nextSpeed: 0,
				prevSpeed: 0,
				preload: 1,
				nextClick: true,
				closeBtn: true,
				maxWidth: $(window).width() * 0.8,
				maxHeight: $(window).height() * 0.8,
				title: $(this).attr('title')
			});
		} catch (ex) {
			console.log(ex);
		}
	}
	

	$("a[href$='.jpg'], a[href$='.png'], a[href$='.gif']").fancybox({
		helpers: {
			title: {
				type: 'over'
			}
		},
		padding: '0',
		maxWidth: $(window).width() * 0.8,
		maxHeight: $(window).height() * 0.8,
		beforeShow: function () {
			var imgAlt = $(this.element).find("img").attr("alt");
			var dataAlt = $(this.element).data("alt");

			$(".fancybox-image").attr("alt", (imgAlt ? imgAlt : dataAlt));
		}
	});


});



if ($('#number-selection').length > 0) {
	var headerHeight = $('header').outerHeight() + 50; // true value, adds margins to the total height
	var footerHeight = $('footer').outerHeight() + 350;
	$('#number-selection').affix({
		offset: {
			top: headerHeight,
			bottom: footerHeight
		}
	}).on('affix.bs.affix', function () { // before affix
		$(this).css({
			/*'top': headerHeight,*/    // for fixed height
			'width': $(this).outerWidth()  // variable widths
		});
	});
}


if ($('.owl-carousel').length > 0) {

	$(document).ready(function () {
		$(".owl-carousel").owlCarousel();
	});

}

/* При клике показываем или скрываем кнопки мессенджеров */
var menuBtn = $('.messenger-btn'),
	menu = $('.messenger-links');
menuBtn.on('click', function () {
	if (menu.hasClass('show')) {
		menu.removeClass('show');
	} else {
		menu.addClass('show');
	}
});

/*  Скрыть div при клике в любом месте сайта кроме самого div */
$(document).mouseup(function (e) {
	var div = $('.messenger');
	if (!div.is(e.target)
		&& div.has(e.target).length === 0) {
		$('.messenger-links').removeClass('show');
	}
});
